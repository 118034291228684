// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@fortawesome/fontawesome-free/css/all.css'
import 'flag-icons/css/flag-icons.min.css'

require('regenerator-runtime/runtime')
require('@rails/ujs')
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('bootstrap')
require('../stylesheets/application.scss')
require('../helpers/compute_hash')
window.QRCode = require('qrcode')

// Supports using $ in the Chrome console
window.$ = window.jQuery = $

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars
